import { h, Component } from 'preact';
import { Router } from 'preact-router';
import queryString from 'query-string';
import { IntlProvider } from 'preact-i18n';
// Code-splitting is automated for routes
import Start from '../routes/start';
import Apply from '../routes/apply';
import Receive from '../routes/receive';
import Address from '../routes/address';
import AddressError from '../routes/addressError';
import OnChain from '../routes/onChain';
import en from '../locales/en.json';
import zh from '../locales/zh.json';
import kr from '../locales/kr.json';
import Toast from 'react-toast-mobile';

export default class App extends Component {
  /** Gets fired when the route changes.
   *    @param {Object} event        "change" event from [preact-router](http://git.io/preact-router)
   *    @param {string} event.url    The newly routed URL
   */
  state = {
    definition: zh,
    locale: 'zh'
  };

  componentDidMount() {
    console.log('mounted');

    const parsed = queryString.parse(location.search);

    if (parsed.campaignID) {
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('campaignID', parsed.campaignID);
      }
    }
    if (parsed.isDebug) {
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('isDebug', parsed.isDebug);
      }
    }

    if (parsed.locale == 'kr') {
      this.setState({
        definition: kr,
        locale: 'kr'
      });
    } else if (parsed.locale == 'en') {
      this.setState({
        definition: en,
        locale: 'en'
      });
    } else {
      this.setState({
        definition: zh,
        locale: 'zh'
      });
    }
  }

  handleRoute = e => {
    this.currentUrl = e.url;
  };

  changeLocale = (definition, locale) => {
    this.setState({
      definition,
      locale
    });
  };

  render() {
    console.log(this.state.locale + ' app');
    return (
      <IntlProvider definition={this.state.definition}>
        <div id="app">
          <Toast />
          <Router onChange={this.handleRoute}>
            <Start
              path="/"
              changeLocale={this.changeLocale}
              locale={this.state.locale}
            />
            <Address path="/address" />
            <AddressError path="/addressError" />
            <OnChain path="/onChain" />
            <Apply path="/apply" />
            <Receive path="/receive" />
          </Router>
        </div>
      </IntlProvider>
    );
  }
}
